import React from 'react';
import Media from 'react-media';
import GreenDecorate from '../Components/GreenDecorate';
import Button from '../Components/Button';
import caroCircle from '../images/caroCircle.png';
import Facebook from '../images/Facebook.png';
import Twitter from '../images/Twitter.png';
import Linkedln from '../images/Linkedln.png';
import dwl from '../images/download-b.png'
import '../Components/styles/GreenDecorate.scss';
import alter from '../images/trayectoria.png';
import cv from '../docs/cv.pdf';
import { HashLink as Link } from 'react-router-hash-link'
import './styles/Trayectoria.scss';

class Trayectoria extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      details: props.data.trayectoria.details,
      timeLine: props.data.trayectoria.timeLine,
      activar: false,
    }
  }

  componentDidMount() {

    const details = document.getElementById('details-carrer')
    details.innerHTML = this.props.data.details

    const timeLine = document.getElementById('details-line')
    timeLine.innerHTML = this.props.data.timeLine

    if (visualViewport.width > 750) {
      console.log('entreee')
      this.props.differNav(this.state.activar, 'trayectoria')
    } else {
      this.props.differNav(false, 'trayectoria')
      this.setState({
        ...this.state.details,
        activar: false,
      })
    }

  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      details: nextProps.data.trayectoria.details,
      timeLine: nextProps.data.trayectoria.timeLine,
    })
    const details = document.getElementById('details-carrer')
    details.innerHTML = nextProps.data.trayectoria.details
    const timeLine = document.getElementById('details-line')
    timeLine.innerHTML = nextProps.data.trayectoria.timeLine
  }


  render() {

    return (

      <>
        <div className="GreenDecorate">
          <img src={alter} alt="" />
          <span> {this.props.data.nav.menu2} </span>
        </div>
        <section className="Trayectoria">
          <Media query="(max-width: 750px)"
            onChange={(e) => {
              if (this.state.activar === false && !e) {
                console.log('me active')
                this.props.differNav(true, 'trayectoria')
                this.setState({
                  ...this.state.details,
                  activar: true,
                })

              } else if (this.state.activar === true && e) {
                console.log('me des-active', e)
                this.props.differNav(false, 'trayectoria')
                this.setState({
                  ...this.state.details,
                  activar: false,
                })
              }
            }}
            render={() => {

              return (
                <>

                  <div className="Trayectoria-container">
                    <div className="Trayectoria__profile" >
                      <div className="Trayectoria__profile-social" >
                        <a target="_blank" href="https://www.facebook.com/CastellanosCCMX"> <img src={Facebook} alt="" /></a>
                        <a target="_blank" href="https://twitter.com/castellanosccmx?lang=en"><img src={Twitter} alt="" /></a>
                        <a target="_blank" href="https://www.linkedin.com/in/carolina-castellanos-lópez-418a6a24/"><img src={Linkedln} alt="" /></a>
                      </div>
                      <div className="Trayectoria__profile-img" >
                        <img src={caroCircle} alt="foto de carolina en circulo" />
                      </div>
                    </div>
                    <h1 className="Trayectoria__little-name">Carolina Castellanos López</h1>
                    <h5 className="Trayectoria__little-job">{this.props.data.homeMain.cargo}</h5>
                    <div className="Trayectoria__little-button" >
                      <Link onClick={() => { window.scrollTo(0, 0); }} to="/contact#inicio" >
                        <Button message={this.props.data.trayectoria.sendButton} />
                      </Link>
                    </div>
                    <div className="cvpdf" style={{ width: "100%" }}>
                      {/* <a href={cv} target="_blank"><img src={dwl} alt="" /></a> */}
                      <a href={this.prps.data.trayectoria.bio} target="_blank"><img src={dwl} alt="" /></a>

                      {/* <p>CV pdf</p> */}
                      <p>Download Semblanza</p>

                    </div>
                    <h3 className="Trayectoria__little-title"> {this.props.data.trayectoria.title} </h3>
                    <li className="Trayectoria__little-details" id="details-carrer">  </li>
                    <div className="resumenAll" style={{ marginTop: 15, listStyle: "none" }}>
                      <div className="Resumen">
                        <h4>{this.props.data.trayectoria.title2}</h4>
                        <div></div>
                      </div>
                      <li className="Trayectoria__right-details" id="details-line">  </li>
                    </div>
                  </div>
                </>
              )
            }}
          />
          <>
            <div className="Tayectoria__left">
              <div className="Trayectoria__left-img">
                <img src={caroCircle} alt="foto de carolina en circulo" />
              </div>
              <Link onClick={() => { window.scrollTo(0, 0); }} to="/contact#inicio" ><Button Linkedln message={this.props.data.trayectoria.sendButton} /></Link>
              <div className="cvpdf">
                {/* <a href={cv} target="_blank"><img src={dwl} alt="" /></a> */}
                <a href={this.props.data.trayectoria.bio} target="_blank"><img src={dwl} alt="" /></a>
                {/* <a>{this.props.data.trayectoria.bio}</a> */}
                <p>CV pdf</p>
                {/* <p>Semblanza</p> */}

              </div>

            </div>
            <div className="Trayectoria__right">
              <h1 className="Trayectoria__right-name">Carolina Castellanos López</h1>
              <h5 className="Trayectoria__right-job">{this.props.data.homeMain.cargo}</h5>
              <div className="Trayectoria__right-social">
                <a target="_blank" href="https://www.facebook.com/CastellanosCCMX"> <img src={Facebook} alt="" /></a>
                <a target="_blank" href="https://twitter.com/castellanosccmx?lang=en"><img src={Twitter} alt="" /></a>
                <a target="_blank" href="https://www.linkedin.com/in/carolina-castellanos-lópez-418a6a24/"><img src={Linkedln} alt="" /></a>
              </div>
              <h3 className="Trayectoria__right-title"> {this.props.data.trayectoria.title} </h3>
              <li className="Trayectoria__right-details" id="details-carrer">  </li>
              <div className="resumenAll">
                <div className="Resumen">
                  <h4>{this.props.data.trayectoria.title2}</h4>
                  <div></div>
                </div>
                <li className="Trayectoria__right-details" id="details-line">  </li>
              </div>
            </div>
          </>

        </section>
      </>
    )

  }

}

export default Trayectoria;